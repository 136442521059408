.reschedule_import_status{
  position: relative;
}

.reschedule_import_status__dropdown{
  position: relative;
  text-align: left;
}
.reschedule_import_status__dropdown_body{
  border: 1px solid rgba(34,36,38,.15);
  max-height: 150px;
  width: 300px;
  padding: 10px 10px;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 300;
  background: #fff;
}

.reschedule_import_status__body{
  position: relative;
}

.reschedule_import_status__body.loading:after{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  content: 'Loading...'
}

.reschedule_import_status__status{
  text-transform: capitalize;
  padding-left: 5px;
}

.reschedule_import_status__status:before{
  content: '';
  height: 6px;
  width: 6px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  top: -1px;
  left: -5px;
}
.reschedule_import_status__status.failed:before{
  background-color: #f5222d;
}
.reschedule_import_status__status.in_progress:before{
  background-color: #f0ad4e;
}
.reschedule_import_status__status.completed:before{
  background-color: #27B394;
}

.reschedule_import_status__errors{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.reschedule_import_status__errors > span + span{
  margin-top: 3px;
}

.reschedule_import_status__body__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
