@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
          animation-name: zoomOut;
}

/* Not needed with the cssTransition helper */

.animate {
  -webkit-animation-duration: 800ms;
          animation-duration: 800ms;
}
.dropzone{
  border: none;
}

.dropzone__preview{
  cursor: pointer;
}

.dropzone__container{
  margin: 15px auto 23px;
  padding: 10px;
  text-align: center;
  background-color: #F4F7FA;
  border: 2px dashed rgb(152, 194, 228);
  border-radius: 3px;
}

.dropzone__title{
  margin: 0 auto 10px;
  max-width: 240px;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
}

.dropzone__text,
.dropzone__hint,
.dropzone__file_name{
  opacity: 0.7;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  color: #0F2522;
}

.dropzone__text{
  margin-bottom: 23px;
}

.dropzone__hint{
  margin-top: 10px;
}
.rotate {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.slick-list,
.slick-slider,
.slick-track {
	position: relative;
	display: block
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
	visibility: hidden
}

.slick-slider {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent
}

.slick-slider * {
	outline: none!important;
}

.slick-list {
	overflow: hidden;
	margin: 0;
	padding: 0
}

.slick-list:focus {
	outline: 0
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0)
}

.slick-track {
	top: 0;
	left: 0
}

.slick-track:after,
.slick-track:before {
	display: table;
	content: ''
}

.slick-track:after {
	clear: both
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px
}

[dir=rtl] .slick-slide {
	float: right
}

.slick-slide.slick-loading img {
	display: none
}

.slick-slide.dragging img {
	pointer-events: none
}

.slick-initialized .slick-slide {
	display: block
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent
}

.slick-arrow.slick-hidden {
	display: none
}

.slick-dots,
.slick-next,
.slick-prev {
	position: absolute;
	display: block;
	padding: 0;
}

.slick-next,
.slick-prev {
	font-size: 0;
	line-height: 0;
	top: 50%;
	width: 20px;
	height: 20px;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: 0;
	background: 0 0
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
	color: transparent;
	outline: 0;
	background: 0 0
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
	opacity: 1
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
	opacity: .25
}

.slick-next:before,
.slick-prev:before {
	font-size: 20px;
	line-height: 1;
	opacity: .75;
	color: #fff
}

.slick-prev {
	left: -25px
}

[dir=rtl] .slick-prev {
	right: -25px;
	left: auto
}

.slick-prev:before {
	content: 'prev'
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
	content: 'prev'
}

.slick-next {
	right: -25px
}

[dir=rtl] .slick-next {
	right: auto;
	left: -25px
}

[dir=rtl] .slick-next:before {
	content: 'next'
}

.slick-dotted.slick-slider {
	margin-bottom: 30px
}

.slick-dots {
	bottom: -52px;
	width: 100%;
	margin: 0;
	list-style: none;
	text-align: center;
}

.slick-dots li {
	position: relative;
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 10px;
	height: 10px;
	padding: 5px;
	cursor: pointer;
	color: transparent;
	border: 0;
	outline: 0;
	background: 0 0
}

.slick-dots li button:focus,
.slick-dots li button:hover {
	outline: 0
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
	opacity: 1
}

.slick-dots li button:before {
	font-size: 6px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 10px;
	height: 10px;
	content: '';
	text-align: center;
	opacity: .25;
	color: #000;
	background: #5f666c;
	border-radius: 100%;
}

.slick-dots li.slick-active button:before {
	opacity: .75;
	color: #000
}
.reschedule_import_status{
  position: relative;
}

.reschedule_import_status__dropdown{
  position: relative;
  text-align: left;
}
.reschedule_import_status__dropdown_body{
  border: 1px solid rgba(34,36,38,.15);
  max-height: 150px;
  width: 300px;
  padding: 10px 10px;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 300;
  background: #fff;
}

.reschedule_import_status__body{
  position: relative;
}

.reschedule_import_status__body.loading:after{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  content: 'Loading...'
}

.reschedule_import_status__status{
  text-transform: capitalize;
  padding-left: 5px;
}

.reschedule_import_status__status:before{
  content: '';
  height: 6px;
  width: 6px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  top: -1px;
  left: -5px;
}
.reschedule_import_status__status.failed:before{
  background-color: #f5222d;
}
.reschedule_import_status__status.in_progress:before{
  background-color: #f0ad4e;
}
.reschedule_import_status__status.completed:before{
  background-color: #27B394;
}

.reschedule_import_status__errors{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.reschedule_import_status__errors > span + span{
  margin-top: 3px;
}

.reschedule_import_status__body__header{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.reschedule_table .public_fixedDataTable_main {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  outline: none!important;
}

.reschedule_table .required_field_badge{
  background-color: #f5222d;
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.reschedule_table .fixedDataTableRowLayout_rowWrapper {
  /* z-index: 0!important; */
}

.reschedule_table .fixedDataTableLayout_horizontalScrollbar {
  z-index: 0;
}

.reschedule_table .public_fixedDataTable_main:focus {
  outline: none!important;
}

.reschedule_table__nodata{
  padding: 9px;
  text-align: center
}

.schedule_table__title.empty{
  margin-bottom: 0;
}

.reschedule_table .public_fixedDataTable_header, .reschedule_table .public_fixedDataTable_scrollbarSpacer, .reschedule_table .public_fixedDataTable_header .public_fixedDataTableCell_main{
  background: #fff;
  background-image: none;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.reschedule_table .fixedDataTableCellGroupLayout_cellGroupWrapper {
  border-top: 1px solid #E1E5E7;
}
.reschedule_table .public_fixedDataTable_header .fixedDataTableCellGroupLayout_cellGroupWrapper {
  border-top: 1px solid transparent;
}
.reschedule_table .public_fixedDataTableCell_main {
  border: none;
}
.reschedule_table .reschedule_table__hcell {
  font-size: 14px;
}
.reschedule_table .reschedule_table__hcell.center{
  text-align: center;
}
.reschedule_table .reschedule_table__hcell.right{
  text-align: right;
}
.reschedule_table .reschedule_table__hcell.left{
  text-align: left;
}

.reschedule_table .reschedule_table__cell{
  height: 100%;
  max-width: 100%;
  padding: 5px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: initial;
      justify-content: initial;
  line-height: 16px;
  font-size: 14px;
}

.reschedule_table .reschedule_table__cell.center{
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
}
.reschedule_table .reschedule_table__cell.left{
  -ms-flex-pack: start;
      justify-content: flex-start;
}
.reschedule_table .reschedule_table__cell.right{
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.reschedule_table .reschedule_table__cell.crm__fields{
  background-color: #fff0dc!important;
}

.reschedule_table .reschedule_table__cell .editable-cell-input-wrapper{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  min-width: 100%;
}
.reschedule_table .reschedule_table__cell .editable-cell-input-wrapper .editable-cell-icon-check{
  margin-left: 5px;
  cursor: pointer;
}

.reschedule_table .application_cell__line{
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 280px;
}

.reschedule_table .application_cell__line > div{
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 280px;
}

.reschedule_table .reschedule_table__cell .editable-cell-link{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.reschedule_table .reschedule_table__cell:before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
}

.reschedule_table .editable-cell-indicator{
  position: absolute;
  bottom: 1px;
  right: 3px;
  font-size: 10px;
  font-style: italic;
  color: #C7C7C7;
}

.reschedule_table__head{
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px 0 10px;
  -ms-flex-align: start;
      align-items: flex-start;
}

.reschedule_table .text-overflowed{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  max-width: 180px;
}

.reschedule_table .pointer{
  cursor: pointer;
}
.reschedule_table .ant-input{
  border: 1px solid hsl(0,0%,80%);
  /* border-radius: 0; */
}

.reschedule_table  .ant-input:focus {
  border-color: #479CCF
}
.reschedule_table .ant-input::-webkit-input-placeholder {
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::-moz-placeholder {
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input:-ms-input-placeholder {
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::-ms-input-placeholder {
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::placeholder {
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::-moz-placeholder{
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::-ms-input-placeholder{
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::-webkit-input-placeholder{
  color: #BBC7D1!important;
  opacity: 1;
}



.reschedule_table .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #D3DBE2;
}

.reschedule_table .form__control{
  border-radius: 0;
  -webkit-appearance: none;
}

.reschedule_table .ant-calendar-picker:hover .ant-calendar-picker-clear:before {
  content: '\D7';
  font-size: 20px;
}

.reschedule_table .ant-calendar-picker:hover .ant-calendar-picker-clear {
  margin-top: -9px;
}

.verified-counter{
  font-weight: 300;
}

