.reschedule_table .public_fixedDataTable_main {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  outline: none!important;
}

.reschedule_table .required_field_badge{
  background-color: #f5222d;
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.reschedule_table .fixedDataTableRowLayout_rowWrapper {
  /* z-index: 0!important; */
}

.reschedule_table .fixedDataTableLayout_horizontalScrollbar {
  z-index: 0;
}

.reschedule_table .public_fixedDataTable_main:focus {
  outline: none!important;
}

.reschedule_table__nodata{
  padding: 9px;
  text-align: center
}

.schedule_table__title.empty{
  margin-bottom: 0;
}

.reschedule_table .public_fixedDataTable_header, .reschedule_table .public_fixedDataTable_scrollbarSpacer, .reschedule_table .public_fixedDataTable_header .public_fixedDataTableCell_main{
  background: #fff;
  background-image: none;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.reschedule_table .fixedDataTableCellGroupLayout_cellGroupWrapper {
  border-top: 1px solid #E1E5E7;
}
.reschedule_table .public_fixedDataTable_header .fixedDataTableCellGroupLayout_cellGroupWrapper {
  border-top: 1px solid transparent;
}
.reschedule_table .public_fixedDataTableCell_main {
  border: none;
}
.reschedule_table .reschedule_table__hcell {
  font-size: 14px;
}
.reschedule_table .reschedule_table__hcell.center{
  text-align: center;
}
.reschedule_table .reschedule_table__hcell.right{
  text-align: right;
}
.reschedule_table .reschedule_table__hcell.left{
  text-align: left;
}

.reschedule_table .reschedule_table__cell{
  height: 100%;
  max-width: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: initial;
  line-height: 16px;
  font-size: 14px;
}

.reschedule_table .reschedule_table__cell.center{
  justify-content: center;
  text-align: center;
}
.reschedule_table .reschedule_table__cell.left{
  justify-content: flex-start;
}
.reschedule_table .reschedule_table__cell.right{
  justify-content: flex-end;
}

.reschedule_table .reschedule_table__cell.crm__fields{
  background-color: #fff0dc!important;
}

.reschedule_table .reschedule_table__cell .editable-cell-input-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
}
.reschedule_table .reschedule_table__cell .editable-cell-input-wrapper .editable-cell-icon-check{
  margin-left: 5px;
  cursor: pointer;
}

.reschedule_table .application_cell__line{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 280px;
}

.reschedule_table .application_cell__line > div{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 280px;
}

.reschedule_table .reschedule_table__cell .editable-cell-link{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.reschedule_table .reschedule_table__cell:before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
}

.reschedule_table .editable-cell-indicator{
  position: absolute;
  bottom: 1px;
  right: 3px;
  font-size: 10px;
  font-style: italic;
  color: #C7C7C7;
}

.reschedule_table__head{
  display: flex;
  padding: 10px 10px 0 10px;
  align-items: flex-start;
}

.reschedule_table .text-overflowed{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
}

.reschedule_table .pointer{
  cursor: pointer;
}
.reschedule_table .ant-input{
  border: 1px solid hsl(0,0%,80%);
  /* border-radius: 0; */
}

.reschedule_table  .ant-input:focus {
  border-color: #479CCF
}
.reschedule_table .ant-input::placeholder {
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::-moz-placeholder{
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::-ms-input-placeholder{
  color: #BBC7D1!important;
  opacity: 1;
}
.reschedule_table .ant-input::-webkit-input-placeholder{
  color: #BBC7D1!important;
  opacity: 1;
}



.reschedule_table .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #D3DBE2;
}

.reschedule_table .form__control{
  border-radius: 0;
  -webkit-appearance: none;
}

.reschedule_table .ant-calendar-picker:hover .ant-calendar-picker-clear:before {
  content: '×';
  font-size: 20px;
}

.reschedule_table .ant-calendar-picker:hover .ant-calendar-picker-clear {
  margin-top: -9px;
}

.verified-counter{
  font-weight: 300;
}
