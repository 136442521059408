.dropzone{
  border: none;
}

.dropzone__preview{
  cursor: pointer;
}

.dropzone__container{
  margin: 15px auto 23px;
  padding: 10px;
  text-align: center;
  background-color: #F4F7FA;
  border: 2px dashed rgb(152, 194, 228);
  border-radius: 3px;
}

.dropzone__title{
  margin: 0 auto 10px;
  max-width: 240px;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
}

.dropzone__text,
.dropzone__hint,
.dropzone__file_name{
  opacity: 0.7;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  color: #0F2522;
}

.dropzone__text{
  margin-bottom: 23px;
}

.dropzone__hint{
  margin-top: 10px;
}